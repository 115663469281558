import { cloneDeep } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType, DairyV2Farms } from '../../common';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { StageType } from '../../../../../../graphql/types';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import ReactHookDsmPicker from '../../../../../modules/Helpers/ReactHookDsmDatePicker';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import DairyV2InputPartExternalSource from './DairyV2InputPartExternalSource';
import DairyV2InputPartInternalSource from './DairyV2InputPartInternalSource';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { AnimalProductivity } from '../../../../../../graphql/generated/blonk/cattle';
import { defaultValues } from '../../baselineDefaultValues';
import {
  DairyV2Baseline,
  DairyV2DairyCowStageData,
  DairyV2Input,
} from '../../../../models/Baseline/DairyV2Baseline';
import { Tooltip } from '@material-ui/core';
import WarningDialog from '../../../helpers/WarningDialog';

const dairyInputStageDefaultValues =
  defaultValues.DAIRY_V2.stages[0].stageData.input;

export interface DairyV2InputDialogProps extends BaselineDialogProps {
  farms: DairyV2Farms[];
  farmId: string;
  farmName: string;
  productionProcessName: string;
}

const DairyV2InputDairyCowDialog: FC<DairyV2InputDialogProps> = ({
  farms,
  farmId,
  farmName,
  productionProcessName,
  formType = FormType.Add,
  itemIndex = 0,
  formVisible,
  handleCancel,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const fieldItemPrefix = `stages.${itemIndex}.stageData.input`;
  const currResetValue = useRef<DairyV2Input>();
  const formContext = useFormContext<DairyV2Baseline>();
  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();

  const {
    fields: internalSources,
    append: appendInternal,
    remove: removeInternal,
  } = useFieldArray({
    name: `${fieldItemPrefix}.internalSources`,
    control: formContext.control,
    keyName: 'keyId',
  });
  const {
    fields: externalSources,
    append: appendExternal,
    remove: removeExternal,
  } = useFieldArray({
    name: `${fieldItemPrefix}.externalSources`,
    control: formContext.control,
    keyName: 'keyId',
  });

  const addInternalSource = () => {
    appendInternal({ ...dairyInputStageDefaultValues.internalSources[0] });
  };

  const removeInternalSource = (index: number) => {
    removeInternal(index);
  };

  const addExternalSource = () => {
    appendExternal({ ...dairyInputStageDefaultValues.externalSources[0] });
  };

  const removeExternalSource = (index: number) => {
    removeExternal(index);
  };

  useEffect(() => {
    if(formVisible){
      currResetValue.current = cloneDeep(formContext.getValues(fieldItemPrefix));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as DairyV2Baseline;
      const dairyCowStageData = resetObject.stages[itemIndex]
        ?.stageData as DairyV2DairyCowStageData;
      if (dairyCowStageData.input) {
        dairyCowStageData.input = currResetValue.current;
        formContext.reset(resetObject, { errors: true });
      }
    }

    if (handleCancel) handleCancel('reset');
  };

  const handleSaveClick = async () => {
    await formContext.trigger(fieldItemPrefix);
    handleCancel('confirm');
  };

  const cattleProductivityOptions = () =>
    Object.entries(AnimalProductivity).map(([key, value]) => ({
      text: key,
      value,
    }));

  const resetToDefaultValues = () => {
    // reset cattleProductivity
    formContext.setValue(
      `${fieldItemPrefix}.cattleProductivity`,
      dairyInputStageDefaultValues.cattleProductivity
    );
    formContext.clearErrors(`${fieldItemPrefix}.cattleProductivity`);
    // reset weightLoss from internalSources
    internalSources?.forEach((_, index) => {
      formContext.setValue(
        `${fieldItemPrefix}.internalSources.${index}.shrinkingRate`,
        dairyInputStageDefaultValues.internalSources[0].shrinkingRate
      );
      formContext.clearErrors(
        `${fieldItemPrefix}.internalSources.${index}.shrinkingRate`
      );
    });
    externalSources?.forEach((_, index) => {
      formContext.setValue(
        `${fieldItemPrefix}.externalSources.${index}.shrinkingRate`,
        dairyInputStageDefaultValues.externalSources[0].shrinkingRate
      );
      formContext.clearErrors(`${fieldItemPrefix}.externalSources.${index}.shrinkingRate`);
    });
  };

  return (
    <div>
      <DialogContainer
        formVisible={formVisible}
        handleClose={handleResetClick}
        iconCode="general/check-heart"
        formTitle={intl.formatMessage({
          id: 'SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE',
        })}
        variant="wide"
      >
        {openDescriptionDialog && (
          <WarningDialog
            isOpen
            closeModal={() => setOpenDescriptionDialog(false)}
            title="SUSTELL.STAGE.DAIRY.INPUT.CATTLE_PRODUCTIVITY"
            body="SUSTELL.STAGE.DAIRY.DEFINITIONS.COMING_SOON"
          />
        )}
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
          <ReactHookDsmSelect
            name={`${fieldItemPrefix}.cattleProductivity`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.INPUT.CATTLE_PRODUCTIVITY',
            })}
            options={cattleProductivityOptions()}
            disabled={formType === FormType.View}
            tooltip={intl.formatMessage({ 
              id: 'SUSTELL.STAGE.DAIRY.INPUT.CATTLE_PRODUCTIVITY.TOOLTIP' 
            })}
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.cattleProductivity`
            )}
          />
          <ReactHookDsmPicker
            name={`${fieldItemPrefix}.startDate`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.INPUT.START_DATE',
            })}
            tooltip={intl.formatMessage({ 
              id: 'SUSTELL.STAGE.DAIRY.INPUT.START_DATE.TOOLTIP' 
            })}
            disabled={formType === FormType.View}
            defaultValue={formContext.getValues(`${fieldItemPrefix}.startDate`)}
          />
          <DsmButton variant="text" className="mb-5" onClick={() => setOpenDescriptionDialog(true)}>
            <DsmIcon slot="before" name="general/eye" />
            {intl.formatMessage({ id: 'DATABASE_FOUNDATION_DEFINITIONS' })}
          </DsmButton>
        </DsmGrid>
        <p className={classes.headingTitle}>
          {intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.COWS' })}
        </p>
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacingWithBorder}>
          <p className={classes.headingTitle}>
            {intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.STOCK' })}
          </p>
          <div/>
          <p>
            {intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.STOCK_DESCRIPTION' })}
          </p>
          <div/>
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.animalsPresentAtStart`}
            type="number"
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_START',
            })}
            tooltip={intl.formatMessage({ 
              id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_START.TOOLTIP' 
            })}
            disabled={formType === FormType.View}
            required
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.animalsPresentAtStart`
            )}
            adornment={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_START.ADORNMENT',
            })}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.animalsPresentAtEnd`}
            type="number"
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_END',
            })}
            disabled={formType === FormType.View}
            tooltip={intl.formatMessage({ 
              id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_END.TOOLTIP' 
            })}
            required
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.animalsPresentAtEnd`
            )}
            adornment={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.INPUT.ANIMALS_PRESENT_END.ADORNMENT',
            })}
          />
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.averageWeightAtStart`}
            type="number"
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_START',
            })}
            tooltip={intl.formatMessage({ 
              id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_START.TOOLTIP' 
            })}
            disabled={formType === FormType.View}
            required
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.averageWeightAtStart`
            )}
            adornment={intl.formatMessage(
              {
                id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_START.ADORNMENT',
              },
              {
                unit: userUOM?.unitBarnOutputMass
                  ? unitLong(userUOM.unitBarnOutputMass)
                  : 'kg',
              }
            )}
          />
        </DsmGrid>
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacingWithBorder}>
          <p className={classes.headingTitle}>
            {intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.NEW_ANIMALS' })}
          </p>
          <div/>
          <p>
            {intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.INPUT.NEW_ANIMALS_DESCRIPTION' })}
          </p>
          <div/>
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.averageWeightNewAnimals`}
            type="number"
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_NEW_ANIMALS',
            })}
            disabled={formType === FormType.View}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_NEW_ANIMALS.TOOLTIP',
            })}
            required
            defaultValue={formContext.getValues(
              `${fieldItemPrefix}.averageWeightNewAnimals`
            )}
            adornment={intl.formatMessage(
              {
                id: 'SUSTELL.STAGE.DAIRY.INPUT.AVERAGE_WEIGHT_NEW_ANIMALS.ADORNMENT',
              },
              {
                unit: userUOM?.unitBarnOutputMass
                  ? unitLong(userUOM.unitBarnOutputMass)
                  : 'kg',
              }
            )}
          />
          <div/>
          <div>
            <label
              htmlFor="internalSource"
              className={classes.headingRadioButtonTitle}
            >
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.INPUT.INTERNAL_SOURCE',
              })}
            </label>
            <div>
              {internalSources &&
                internalSources?.map((item, index) => (
                  <DairyV2InputPartInternalSource
                    key={item.keyId}
                    farms={farms}
                    farmId={farmId}
                    farmName={farmName}
                    allowedStagesForFarm={[StageType.DairyCow, StageType.ContinuousGrowing]}
                    productionProcessName={productionProcessName}
                    stageIndex={itemIndex}
                    itemIndex={index}
                    fieldPrefix={fieldItemPrefix}
                    removeHandler={removeInternalSource}
                    stageType={StageType.DairyCow}
                    formType={formType}
                  />
                ))}
              <DsmButton
                variant="text"
                onClick={addInternalSource}
                disabled={formType === FormType.View}
              >
                <DsmIcon slot="before" name="general/plus-circle" />
                {intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.INPUT.INTERNAL_SOURCE.ADDITIONAL',
                })}
              </DsmButton>
            </div>
          </div>
          <div>
            <label
              htmlFor="externalSources"
              className={classes.headingRadioButtonTitle}
            >
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.INPUT.EXTERNAL_SOURCE',
              })}
            </label>
            <div>
              {externalSources &&
                externalSources?.map((item, index) => (
                  <DairyV2InputPartExternalSource
                    key={item.keyId}
                    stageIndex={itemIndex}
                    itemIndex={index}
                    propName="externalSources"
                    removeHandler={removeExternalSource}
                    formType={formType}
                  />
                ))}
              <DsmButton
                variant="text"
                onClick={addExternalSource}
                disabled={formType === FormType.View}
              >
                <DsmIcon slot="before" name="general/plus-circle" />
                {intl.formatMessage({
                  id: 'SUSTELL.STAGE.DAIRY.INPUT.EXTERNAL_SOURCE.ADDITIONAL',
                })}
              </DsmButton>
            </div>
          </div>
        </DsmGrid>
        <div className={classes.resetButtonContainer}>
          <DsmButton
            widthFull
            variant="text"
            onClick={() => resetToDefaultValues()}
            disabled={formType === FormType.View}
            className="mt-3"
          >
            <DsmIcon slot="before" name="arrows/refresh-cw-01" />
            <u>
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.DAIRY.RESET_DEFAULT_VALUES',
              })}
            </u>
          </DsmButton>
          <Tooltip
            title={intl.formatMessage({
              id: 'SUSTELL.RESET_DEFAULT.TOOLTIP',
            })}
            placement="left"
            className='ml-3'
            style={{transform: 'translateY(85%)'}}
          >
            <DsmIcon
              name="general/help-circle"
              className={classes.inlineTooltipClass}
              title=""
            />
          </Tooltip>
        </div>
        <DsmButtonControlGroup
          cancelHandler={handleResetClick}
          saveHandler={async () => handleSaveClick()}
          saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
        />
      </DialogContainer>
    </div>
  );
};

export default DairyV2InputDairyCowDialog;
