import { cloneDeep } from 'lodash';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { BaselineDialogProps } from '../../common';
import {
  DairyV2Baseline,
  DairyV2DairyCowStageData,
  DairyV2ContinuousGrowingStageData,
} from '../../../../models/Baseline/DairyV2Baseline';
import DairyV2EmissionInputsComponent from './DairyV2EmissionInputsComponent';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { FormType } from '../../common';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import ErrorMessageComponent from '../../../helpers/ErrorMessageComponent';
import { DairyV2Emissions } from '../../../../models/Baseline/DairyV2Baseline';

const DairyV2EmissionsDialog: FC<BaselineDialogProps> = ({ 
  formType, 
  itemIndex, 
  handleCancel, 
  formVisible = false 
}) => {
  const intl = useIntl();
  const formContext = useFormContext();
  const currResetValue = useRef<DairyV2Emissions>();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const [stageFormError, setStageFormError] = useState<boolean>(false);

  const basePrefix = `stages.${itemIndex}.stageData.emissions`;
  const fieldItemPrefix = `stages.${itemIndex}.stageData.emissions.emissionMitigations`;
  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE',
  });

  useEffect(() => {
    if (formVisible) {
      currResetValue.current =
        (cloneDeep(formContext.getValues(basePrefix)) as DairyV2Emissions) ||
        {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = useCallback(() => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as DairyV2Baseline;
      const dairyV2Stage = resetObject.stages[itemIndex].stageData as DairyV2ContinuousGrowingStageData | DairyV2DairyCowStageData;
      if (dairyV2Stage?.emissions) {
        dairyV2Stage.emissions = {
          ...currResetValue.current,
        };
        formContext.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) handleCancel('reset');
  }, [formContext, handleCancel, itemIndex]);

  const closeFormError = () => setStageFormError(false);

  return (
    <DialogContainer
      variant="wide"
      iconCode="maps-travel/globe-04"
      formVisible={formVisible}
      formTitle={formTitle}
      handleClose={handleResetClick}
      introText={intl.formatMessage({ id: 'SUSTELL.STAGE.DAIRY.EMISSIONS.INTRO' })}
    >
      {stageFormError && (
        <div style={{ paddingBottom: "var(--dsm-spacing-px-5)" }}>
          <ErrorMessageComponent
            onClose={closeFormError}
            messages={[intl.formatMessage({
              id: 'SUSTELL.STAGE.DAIRY.EMISSIONS.HEADER.3_NOP_ERROR',
            })]}
          />
        </div>
      )}
      <div>
        <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
          <ReactHookDsmInput
            type="number"
            name={`${fieldItemPrefix}.methaneEntericFermentation`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION.TOOLTIP',
            })}
            adornment="%"
            disabled={formType === FormType.View}
            defaultValue={
              formContext.getValues(
                `${fieldItemPrefix}.methaneEntericFermentation`
              ) as string
            }
          />
          <ReactHookDsmInput
            type="number"
            name={`${fieldItemPrefix}.methaneMms`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.METHANE_FROM_MANURE',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.METHANE_FROM_MANURE',
            })}
            adornment="%"
            disabled={formType === FormType.View}
            defaultValue={
              formContext.getValues(`${fieldItemPrefix}.methaneMms`) as string
            }
          />
          <ReactHookDsmInput
            type="number"
            name={`${fieldItemPrefix}.nmvoc`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.NON_METHANE_VOLATILE_ORGANIC_COMPOUNDS_NO_SOURCE',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.NON_METHANE_VOLATILE_ORGANIC_COMPOUNDS_NO_SOURCE',
            })}
            adornment="%"
            disabled={formType === FormType.View}
            defaultValue={
              formContext.getValues(`${fieldItemPrefix}.nmvoc`) as string
            }
          />
          <ReactHookDsmInput
            type="number"
            name={`${fieldItemPrefix}.nitrousOxideDirect`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_DIRECT_NO_SOURCE',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_DIRECT_NO_SOURCE',
            })}
            adornment="%"
            disabled={formType === FormType.View}
            defaultValue={
              formContext.getValues(
                `${fieldItemPrefix}.nitrousOxideDirect`
              ) as string
            }
          />
          <ReactHookDsmInput
            type="number"
            name={`${fieldItemPrefix}.nitrousOxideLeaching`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_EMISSION_MANURE_LEACHING',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_EMISSION_MANURE_LEACHING',
            })}
            adornment="%"
            disabled={formType === FormType.View}
            defaultValue={
              formContext.getValues(
                `${fieldItemPrefix}.nitrousOxideLeaching`
              ) as string
            }
          />
          <ReactHookDsmInput
            type="number"
            name={`${fieldItemPrefix}.nitrogenOxidesStorage`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.NITROGEN_OXIDE_FROM_MANURE_STORAGE',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.NITROGEN_OXIDE_FROM_MANURE_STORAGE',
            })}
            adornment="%"
            disabled={formType === FormType.View}
            defaultValue={
              formContext.getValues(
                `${fieldItemPrefix}.nitrogenOxidesStorage`
              ) as string
            }
          />
          <ReactHookDsmInput
            type="number"
            name={`${fieldItemPrefix}.nitrousOxideVolatilization`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_VOLATILIZATION_FROM_MANURE',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_VOLATILIZATION_FROM_MANURE',
            })}
            adornment="%"
            disabled={formType === FormType.View}
            defaultValue={
              formContext.getValues(
                `${fieldItemPrefix}.nitrousOxideVolatilization`
              ) as string
            }
          />
          <ReactHookDsmInput
            type="number"
            name={`${fieldItemPrefix}.ammoniaHousing`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_HOUSING',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_HOUSING',
            })}
            adornment="%"
            disabled={formType === FormType.View}
            defaultValue={
              formContext.getValues(`${fieldItemPrefix}.ammoniaHousing`) as string
            }
          />
          <ReactHookDsmInput
            type="number"
            name={`${fieldItemPrefix}.ammoniaStorage`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_STORAGE',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_STORAGE',
            })}
            adornment="%"
            disabled={formType === FormType.View}
            defaultValue={
              formContext.getValues(`${fieldItemPrefix}.ammoniaStorage`) as string
            }
          />
          <ReactHookDsmInput
            type="number"
            name={`${fieldItemPrefix}.pm2_5`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_2_5',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_2_5',
            })}
            adornment="%"
            disabled={formType === FormType.View}
            defaultValue={
              formContext.getValues(`${fieldItemPrefix}.pm2_5`) as string
            }
          />
          <ReactHookDsmInput
            type="number"
            name={`${fieldItemPrefix}.ammoniaYard`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_YARD',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_YARD',
            })}
            adornment="%"
            disabled={formType === FormType.View}
            defaultValue={
              formContext.getValues(`${fieldItemPrefix}.ammoniaYard`) as string
            }
          />
          <ReactHookDsmInput
            type="number"
            name={`${fieldItemPrefix}.tsp`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.TSP_NO_SOURCE',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.TSP_NO_SOURCE',
            })}
            adornment="%"
            disabled={formType === FormType.View}
            defaultValue={
              formContext.getValues(`${fieldItemPrefix}.tsp`) as string
            }
          />
          <ReactHookDsmInput
            type="number"
            name={`${fieldItemPrefix}.pm10`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_10',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_10',
            })}
            adornment="%"
            disabled={formType === FormType.View}
            defaultValue={
              formContext.getValues(`${fieldItemPrefix}.pm10`) as string
            }
          />
        </DsmGrid>

        <DsmButtonControlGroup
          cancelHandler={handleResetClick}
          saveHandler={async () => {
            await formContext.trigger(`stages.${itemIndex}.stageData.emissions`);
            handleCancel('confirm');
          }}
          saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
        />
      </div>
    </DialogContainer>
  );
};

export default DairyV2EmissionsDialog;
