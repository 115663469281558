import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmGrid, DsmFieldset, DsmModal } from '@dsm-dcs/design-system-react';

import { UserProfilePrefs } from '../../../../modules/Helpers/UserProfilePrefs';
import SustellRationFeeds from '../../../../modules/Farms/Baseline/SustellRationFeeds_v2';
import ReactHookDsmSelect from '../../../../modules/Helpers/ReactHookDsmSelect2';
import { DialogContainer } from './DialogContainer2';
import { useIntl } from '../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../_metronic/layout';
import DsmButtonControlGroup from '../../helpers/DsmButtonControlGroup';
import SustellSingleIngredientFeed from '../../../../modules/Farms/Baseline/SustellSingleIngredientFeeds_v2';
import { FormType } from '../common';
import { getDatabaseOptions } from '../../CompoundFeedWizard/utils';
import { DsmButtonV2, useButtonStyles } from '../../helpers/DsmButton';

import { StageType } from '../../../../../graphql/types';
import SustellNaturalFeeds from '../../../../modules/Farms/Baseline/SustellNaturalFeeds';
import { isShrimp } from '../../../helpers/animals';

export const FeedsFormDialog = (props) => {
  const {
    formVisible,
    itemIndex = 0,
    handleCancel,
    handleSave = handleCancel,
    formType = 'add',
    stageType,
    animalType,
    masterDataEnums,
  } = props;
  const intl = useIntl();
  const currResetValue = useRef();
  const classes = processAndStageStylesV2();
  const buttonClasses = useButtonStyles();
  const feedItem = { feedType: '', kgPerAnimal: '' };
  const naturalFeedItem = { type: '', quantity: '' };

  const userProfile = UserProfilePrefs.getInstance();
  const precision = userProfile.getUnitBarnOutputMassPrecision();
  const fieldItemPrefix = `stages.${itemIndex}.stageData.feed`;
  const { getValues, setValue, reset, trigger } = useFormContext();
  const calculateInitialDatabaseValue = () => {
    let initValue = getValues(`info.databaseFoundation`);
    if (!initValue) initValue = userProfile.getUserDatabasePrefs().databaseType;
    return initValue;
  };
  const baselineDatabaseFoundation = calculateInitialDatabaseValue();
  const defalutDatabaseFoundation = baselineDatabaseFoundation;
  const [
    openDatabaseSelectionWaringDialog,
    setOpenDatabaseSelectionWaringDialog,
  ] = useState({
    open: false,
    defaultValue: defalutDatabaseFoundation,
    selectedValue: null,
  });

  const { compoundFeeds, singleIngredients, origins } = props;

  const baselineCompoundFeeds = getValues(
    `stages.${itemIndex}.stageData.feed.compoundFeeds`
  );

  const getCompoundFeedsList = (databaseOption) =>
    compoundFeeds?.filter((feed) => feed.databaseFoundation === databaseOption);

  const [compoundFeedData, setCompoundFeedData] = useState(
    baselineCompoundFeeds && baselineCompoundFeeds?.length > 0
      ? baselineCompoundFeeds
      : [{ ...feedItem }]
  );

  const baselineSingleIngredients = getValues(
    `stages.${itemIndex}.stageData.feed.singleIngredients`
  );

  const [singleIngredientsData, setSingleIngredientsData] = useState(
    baselineSingleIngredients && baselineSingleIngredients?.length > 0
      ? baselineSingleIngredients
      : [{ ...feedItem }]
  );

  const baselineNaturalFeeds = getValues(
    `stages.${itemIndex}.stageData.feed.naturalFeed`
  );

  const [naturalFeedsData, setNaturalFeedsData] = useState(
    baselineNaturalFeeds && baselineNaturalFeeds?.length > 0
      ? baselineNaturalFeeds
      : [{ ...naturalFeedItem }]
  );

  useEffect(() => {
    setCompoundFeedData(baselineCompoundFeeds);
    setSingleIngredientsData(baselineSingleIngredients);
  }, []);

  const closeDialog = () => {
    setOpenDatabaseSelectionWaringDialog({
      open: false,
      selectedValue: null,
      defaultValue: defalutDatabaseFoundation,
    });
  };

  const handleDiscard = () => {
    getCompoundFeedsList(openDatabaseSelectionWaringDialog.defaultValue);
    setValue(
      `info.databaseFoundation`,
      openDatabaseSelectionWaringDialog.defaultValue
    );
    const stages = getValues('stages');
    stages.forEach((stage, i) => {
      const updatedFeeds = stage.stageData.feed.compoundFeeds.map((item) => ({
        feedType: item.feedType,
        kgPerAnimal: item.kgPerAnimal,
      }));
      setValue(`stages.${i}.stageData.feed.compoundFeeds`, updatedFeeds);
    });
    closeDialog();
  };

  const handleConfirm = () => {
    closeDialog();
    getCompoundFeedsList(baselineDatabaseFoundation);
    const stages = getValues('stages');
    stages.forEach((stage, i) => {
      if (i === itemIndex) {
        const updatedFeeds = stage.stageData.feed.compoundFeeds.map((item) => ({
          feedType: '',
          kgPerAnimal: 0,
        }));
        setValue(`stages.${i}.stageData.feed.compoundFeeds`, updatedFeeds);
      }
    });
  };

  const handleChangeDatabaseFoundation = (event) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    const option = event.target.value;
    if (baselineDatabaseFoundation !== option) {
      setOpenDatabaseSelectionWaringDialog({
        open: true,
        selectedValue: option,
        defaultValue: defalutDatabaseFoundation,
      });
    }
  };

  const updateTotalFeedIntake = useCallback(() => {
    const currFormValues = getValues();
    const currentCompoundFeeds =
      currFormValues.stages?.[itemIndex]?.stageData?.feed?.compoundFeeds;
    let totalIntake = 0;
    if (currentCompoundFeeds) {
      totalIntake += currentCompoundFeeds.reduce((acc, item) => {
        return (
          acc +
          (item.feedType && !isNaN(item.kgPerAnimal)
            ? Number(item.kgPerAnimal)
            : 0)
        );
      }, 0);
    }
    setValue(
      `${fieldItemPrefix}.totalFeedIntake`,
      totalIntake !== 0 ? totalIntake.toFixed(precision) : ''
    );

    if (formVisible) trigger(`${fieldItemPrefix}.totalFeedIntake`);
  }, [
    itemIndex,
    fieldItemPrefix,
    setValue,
    getValues,
    precision,
    formVisible,
    trigger,
  ]);

  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE',
  });

  const handleSaveClick = async () => {
    const currFormValues = getValues();
    reset(currFormValues, {
      errors: false,
    });
    const stages = currFormValues?.stages || [];
    const val = JSON.parse(currResetValue.current);
    stages.forEach((stage, i) => {
      if (
        i !== itemIndex &&
        val.databaseFoundation !== baselineDatabaseFoundation
      ) {
        const updatedFeeds =
          stage?.stageData?.feed?.compoundFeeds ||
          [].map(() => ({
            feedType: '',
            kgPerAnimal: 0,
          }));
        setValue(`stages.${i}.stageData.feed.compoundFeeds`, updatedFeeds);
      }
    });
    if (handleSave) {
      await trigger(`stages.${itemIndex}.stageData.feed`);
      handleSave('confirm');
    }
  };

  const handleResetClick = async () => {
    if (currResetValue.current) {
      const resetObject = { ...getValues() };
      if (resetObject.stages[itemIndex]?.stageData?.feed) {
        const val = JSON.parse(currResetValue.current);
        if (val?.feed)
          resetObject.stages[itemIndex].stageData.feed = JSON.parse(val.feed);
        resetObject.info.databaseFoundation = val.databaseFoundation;
        reset(resetObject, {
          errors: true,
        });
      }
    }
    if (handleCancel) handleCancel('reset');
  };

  useEffect(() => {
    if (formVisible) {
      // to faster deep copy all potential arrays and subobjects
      const serializedData = JSON.stringify(getValues(fieldItemPrefix));
      currResetValue.current = JSON.stringify({
        feed: serializedData,
        databaseFoundation: baselineDatabaseFoundation,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="shapes/cube-02"
      formTitle={formTitle}
      variant="ultrawide"
    >
      {openDatabaseSelectionWaringDialog.open && (
        <div>
          <DsmModal
            open={openDatabaseSelectionWaringDialog.open}
            icon="general/info-square"
            header={intl.formatMessage({
              id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION_WARNING_HEADER',
            })}
            dsmClosed={() => closeDialog()}
          >
            <p slot="content">
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION_WARNING_BODY',
              })}
            </p>
            <>
              <DsmButtonV2
                slot="actions"
                onClick={handleDiscard}
                className={buttonClasses.secondaryButton}
              >
                {intl.formatMessage({
                  id: 'GENERAL.CANCEL',
                })}
              </DsmButtonV2>
              <DsmButtonV2
                variant="secondary"
                slot="actions"
                onClick={handleConfirm}
              >
                {intl.formatMessage({
                  id: 'GENERAL.CONFIRM',
                })}
              </DsmButtonV2>
            </>
          </DsmModal>
        </div>
      )}
      <DsmGrid className={classes.dsmGridTwoColumn2To3}>
        <DsmFieldset
          style={{ width: '58%', marginTop: `var(--dsm-spacing-px-4)` }}
        >
          <ReactHookDsmSelect
            name="info.databaseFoundation"
            label={`${intl.formatMessage({
              id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION',
            })}`}
            defaultValue={defalutDatabaseFoundation}
            disabled={
              formType === FormType.View ||
              itemIndex >= 1 ||
              formType === FormType.Edit
            }
            options={getDatabaseOptions()}
            changeHandler={(e) => handleChangeDatabaseFoundation(e)}
            tooltip={intl.formatMessage({
              id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELECTION.TOOLTIP',
            })}
            required
          />
        </DsmFieldset>
      </DsmGrid>
      <DsmGrid className={classes.dsmGridTwoColumn2To3}>
        <SustellRationFeeds
          key="compoundFeeds"
          label={intl.formatMessage({ id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED' })}
          availableFeedItems={getCompoundFeedsList(baselineDatabaseFoundation)}
          fieldItemPrefix={fieldItemPrefix}
          subFieldName="compoundFeeds"
          updateTotalFeedIntake={updateTotalFeedIntake}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP',
          })}
          formState={formType}
          compoundFeed
          feedData={compoundFeedData}
          setFeedData={setCompoundFeedData}
          animalType={animalType}
          stageType={stageType}
        />
        <SustellSingleIngredientFeed
          key="singleIngredients"
          label={intl.formatMessage({
            id: 'SUSTELL.STAGE.FEED.SINGLE_INGREDIENT',
          })}
          barnItemIndex={itemIndex}
          availableFeedItems={singleIngredients}
          availableOrigins={origins}
          fieldItemPrefix={fieldItemPrefix}
          subFieldName="singleIngredients"
          updateTotalFeedIntake={updateTotalFeedIntake}
          tooltip={
            <div key="feeds-form-dialog-singleIngredients">
              {intl.formatMessage(
                { id: 'BASELINE.FORM.BARN.RATION.SINGLE_INGREDIENTS.TOOLTIP' },
                { br: <br /> }
              )}
            </div>
          }
          formState={formType}
          feedData={singleIngredientsData}
          setFeedData={setSingleIngredientsData}
          animalType={animalType}
          stageType={stageType}
        />
      </DsmGrid>
      {isShrimp(animalType) && stageType === StageType.Hatching && (
        <DsmGrid className={classes.dsmGridOneColumn}>
          <SustellNaturalFeeds
            key="naturalFeed"
            label={intl.formatMessage({
              id: 'SUSTELL.STAGE.FEED.NATURAL_FEED',
            })}
            barnItemIndex={itemIndex}
            availableFeedItems={masterDataEnums?.NaturalFeedType || []}
            availableOrigins={props.origins}
            fieldItemPrefix={fieldItemPrefix}
            subFieldName="naturalFeed"
            updateTotalFeedIntake={updateTotalFeedIntake}
            tooltip={
              <div key={'feeds-form-dialog-naturalFeed'}>
                {intl.formatMessage(
                  {
                    id: 'BASELINE.FORM.BARN.RATION.SINGLE_INGREDIENTS.TOOLTIP',
                  },
                  { br: <br /> }
                )}
              </div>
            }
            formState={formType}
            feedData={naturalFeedsData}
            setFeedData={setNaturalFeedsData}
          />
        </DsmGrid>
      )}
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleSaveClick}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default FeedsFormDialog;
